import {
  IonCard,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react'
import React from 'react'
import './HomePageLS.scss'
import selectHealth from '../../media/icons/sh_logo_1C_small_rgb_blue.svg'
import monitor from '../../media/icons/icon _ streamline _ e-learning-monitor.svg'
import dollarBills from '../../media/icons/icon _ streamline _ accounting-bills.svg'
import c19Virus from '../../media/icons/icon _ streamline _ germwatch.svg'
import shieldMed from '../../media/icons/icon _ streamline _ shield-med.svg'
import stethoscope from '../../media/icons/icon _ streamline _ stethoscope.svg'
import nurse from '../../media/icons/icon _ streamline _ nurse.svg'
import address from '../../media/icons/icon _ streamline _ address.svg'
import education from '../../media/icons/patient-education-icon.svg'
import { Link } from 'react-router-dom'
import EnvConstants from '../../data/EnvConstants'
import CaregiverImage from '../HomePageRS/CaregiverImage'

const HomePageLS: React.FC = () => {
  return (
    <>
      <IonHeader>
        <CaregiverImage />
      </IonHeader>
      <IonGrid style={{ marginLeft: '45px' }} id="homepage-leftside">
        <br />

        <IonRow>
          <IonCol>
            <a
              href="https://phy.intermountain.net/Pages/home.aspx"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              {/* <IonCard className="ihc-physician">
              <div className="ihc-logo"></div>
              <p className="green-box-text">
                Intermountain Physician.net <br />
                <p className="log-in">Log in to access secure content</p>
              </p>
            </IonCard> */}
            </a>
          </IonCol>
        </IonRow>
        <br />
        {/* <IonRow>
        {' '}
        <IonText className="tool-resources"> Tools &#38; Resources</IonText>
      </IonRow> */}
        <IonRow
          style={{
            marginLeft: '5px',
            marginTop: '15px',
            marginBottom: '-5px',
          }}
        ></IonRow>
        <IonRow style={{ width: '100%' }}>
          <IonCol>
            <a
              href="https://intermountainhealthcare.org/careers/explore-careers/physician-jobs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={stethoscope}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>

                <IonText>Careers</IonText>
              </IonCard>
            </a>
          </IonCol>
          <IonCol>
            <Link
              to="/providers/careprocessmodels"
              style={{ textDecoration: 'none' }}
            >
              <IonCard
                className="option-cards"
                onClick={() => {
                  if (
                    window.location.pathname === '/providers/careprocessmodels'
                  ) {
                    window.location.reload()
                  } else {
                    window.location.href = `${EnvConstants.PUBLIC_URL}/providers/careprocessmodels`
                  }
                }}
              >
                <div>
                  <IonIcon
                    src={monitor}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>

                <IonText>Care Process Models</IonText>
              </IonCard>
            </Link>
          </IonCol>
          <IonCol>
            <Link to="/covid-19/resources" style={{ textDecoration: 'none' }}>
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={shieldMed}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>
                <IonText>Covid-19 Resources</IonText>
              </IonCard>
            </Link>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Link to="/Germwatch" style={{ textDecoration: 'none' }}>
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={c19Virus}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>

                <IonText>Germwatch</IonText>
              </IonCard>
            </Link>
          </IonCol>
          <IonCol>
            <a
              href="https://pde.intermountainhealthcare.org/caregiver/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={nurse}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>

                <IonText>Physician Web Profile</IonText>
              </IonCard>
            </a>
          </IonCol>
          <IonCol>
            <a
              href="https://intermountainphysician.org/providercredential"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={address}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>

                <IonText>Provider Credentialing</IonText>
              </IonCard>
            </a>
          </IonCol>
        </IonRow>
        <IonRow style={{ width: '100%' }}>
          <IonCol>
            <Link to="/providers/payment" style={{ textDecoration: 'none' }}>
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={dollarBills}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>

                <IonText>Provider Payments</IonText>
              </IonCard>
            </Link>
          </IonCol>
          <IonCol>
            <a
              href="https://selecthealth.org/providers"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={selectHealth}
                    style={{
                      marginTop: '35px',
                      height: '62px',
                      width: '100px',
                      marginBottom: '-10px',
                    }}
                  />
                </div>

                <IonText>SelectHealth Physician</IonText>
              </IonCard>
            </a>
          </IonCol>
          <IonCol>
            <a
              href="https://patient.health-ce.wolterskluwer.com/IMHCPATIENT/search"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <IonCard className="option-cards">
                <div>
                  <IonIcon
                    src={education}
                    style={{
                      marginTop: '45px',
                      height: '45px',
                      width: '45px',
                      marginBottom: '5px',
                    }}
                  />
                </div>
                <IonText>Patient Education</IonText>
              </IonCard>
            </a>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}

export default HomePageLS
