import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
  useIonToast,
} from '@ionic/react'
import React, { useEffect, useMemo, useState } from 'react'

import {
  getActivityMapImageList,
  getDiseaseUpdate,
  getEpicurve,
  getGermSchoolData,
  getPathogenTrends,
  getseverityIndicator,
} from '../../data/api/GermWatch'
import './Germwatch-Public.scss'
import {
  DiseaseUpdateList,
  EpicurveDataList,
  GermSchoolData,
  Organism,
  RespiratoryOrganismImageList,
  Season,
  SeverityIndicatorList,
} from '../../interfaces/GermWatchTypes'
import { InteractiveMap } from '../../components/Germwatch/Map/InteractiveMap'
import { PathogenList } from '../../components/Germwatch/PathogenList'
export const GermWatchPublic: React.FC<{}> = () => {

  //States
  const [seasons, setSeasons] = useState<Season[]>([])
  const [respiratory, setRespiratory] = useState<Organism[]>([])
  const [imageList, setImageList] = useState<RespiratoryOrganismImageList>(
    {} as RespiratoryOrganismImageList
  )
  const [gastroIntestinal, setGastroIntestinal] = useState<Organism[]>([])
  const [syndrome, setSyndrome] = useState<Organism[]>([])
  const [epicurve, setEpicurve] = useState<EpicurveDataList[]>([])
  const [severity, setSeverity] = useState<SeverityIndicatorList>()
  const [selectedPathogen, setSelectedPathogen] = useState<Organism>(
    {} as Organism
  )
  // const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [diseaseUpdate, setDiseaseUpdate] = useState<DiseaseUpdateList>(
    {} as DiseaseUpdateList
  )
  const [diseaseUpdateSelected, setdiseaseUpdateSelected] = useState<number>(0)
  const [germSchoolData, setGermSchoolData] = useState<GermSchoolData[]>([])
  const activityImageUrl = useMemo(() => {
    if (Object.keys(imageList).length > 0) {
      const respiratoryOrganism = imageList!.respiratoryOrganismImageList!.find(
        (respOrg) => respOrg.organismCode === selectedPathogen.organismCode
      )
      return respiratoryOrganism && respiratoryOrganism!.imageUrl
    } else return ''
  }, [imageList, selectedPathogen])

  //Toaster
  const [present] = useIonToast()

  //Functions
  const updatePathogen = (newPathogen: Organism, type: string): void => {
    if (selectedPathogen.organismCode === newPathogen.organismCode) {
      return
    } else {
      setIsLoading(true)
      setSelectedPathogen({
        ...newPathogen,
        type,
      })
    }
  }

  const loadSeasons = (): void => {
    const tempSeason: Season[] = []
    let curYear = new Date().getFullYear()
    let curMonth = new Date().getMonth()
    if (curMonth >= 8) curYear = curYear + 1 //new season starts Aug
    let yrStr
    let season: Season
    for (let i = 0; i <= 4; i++) {
      yrStr = (curYear - i).toString().substr(2, 2)
      season = {
        name: curYear - (i + 1) + '-' + yrStr,
        isLoaded: i === 0 ? true : false,
        isSelected: i === 0 ? true : false,
      }
      tempSeason.push(season)
    }
    setSeasons(tempSeason)
  }
  const onLoad = () => {
    getDiseaseUpdate().then((response) => {
      setDiseaseUpdate(response)
    })
    let count = 0 //We will try three times
    async function initialLoad() {
      Promise.all([getActivityMapImageList(), getPathogenTrends()])
        .then((response) => {
          setImageList(response[0])
          // Organize pathogens
          response[1].gastroorganisms.respiratoryOrganismList.sort((a, b) =>
            a.activityNumber > b.activityNumber ? -1 : 1
          )
          response[1].respiratoryorganisms.respiratoryOrganismList.sort(
            (a, b) => (a.activityNumber > b.activityNumber ? -1 : 1)
          )
          response[1].syndromes.respiratoryOrganismList.sort((a, b) =>
            a.activityNumber > b.activityNumber ? -1 : 1
          )
          setGastroIntestinal(
            response[1].gastroorganisms.respiratoryOrganismList
          )
          setRespiratory(
            response[1].respiratoryorganisms.respiratoryOrganismList
          )
          setSyndrome(response[1].syndromes.respiratoryOrganismList)
          //Select top pathogen
          setSelectedPathogen({
            ...response[1].respiratoryorganisms.respiratoryOrganismList[0],
            type: 'respiratory',
          })
        })
        .catch((e) => {
          if (count < 3) {
            count++
            //Wait half a second and try again
            setTimeout(function () {
              initialLoad()
            }, 500)
          } else {
            present({
              message: 'Something failed to load. Please try again',
              duration: 3000,
              position: 'top',
              cssClass: 'custom-toaster',
              color: 'danger',
            })
            setIsLoading(false)
          }
        })
    }
    initialLoad()
  }
  const getEpicurveFromSeason = async (season: string) => {
    setIsLoading(true)
    getEpicurve(selectedPathogen.type, selectedPathogen.organismCode, season)
      .then((response) => {
        const tempEpicurve = [...epicurve]
        tempEpicurve.push({ ...response, isVisible: true })
        setEpicurve(tempEpicurve)
        const tempSeasons = [...seasons]
        const index = tempSeasons.findIndex((x) => x.name === season)
        tempSeasons[index] = {
          ...tempSeasons[index],
          isLoaded: true,
          isSelected: true,
        }
        setSeasons(tempSeasons)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }
  const toggleSeasonVisibility = (season: Season) => {
    const tempEpicurve = [...epicurve]
    const index = tempEpicurve.findIndex((item) => item.season === season.name)
    tempEpicurve[index] = {
      ...tempEpicurve[index],
      isVisible: !tempEpicurve[index].isVisible,
    }
    setEpicurve(tempEpicurve)
  }

  //Effects
  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadSeasons()
    let count = 0 //We will try three times
    //We are creating the function here since we are using Promise.all
    async function updateItems() {
      setIsLoading(true)
      Promise.all([
        getEpicurve(
          selectedPathogen.type,
          selectedPathogen.organismCode,
          seasons[0].name
        ),
        getseverityIndicator(selectedPathogen.organismCode),
        getGermSchoolData(selectedPathogen.organismCode),
      ])
        .then((response) => {
          setEpicurve([{ ...response[0], isVisible: true }])
          setSeverity(response[1])
          setGermSchoolData(response[2])
          setIsLoading(false)
        })
        .catch((e) => {
          if (count < 3) {
            count++
            //Wait half a second and try again
            setTimeout(function () {
              updateItems()
            }, 500)
          } else {
            present({
              message: 'Something failed to load. Please try again',
              duration: 3000,
              position: 'top',
              cssClass: 'custom-toaster',
              color: 'danger',
            })
            setIsLoading(false)
          }
        })
    }
    if (Object.keys(selectedPathogen).length > 0) {
      updateItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPathogen])

  return (
    <div id="germwatch-public" className="page-content">
      <IonLoading isOpen={isLoading} message="Loading..." />
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeXl="12">
            <IonCard className="gw-card">
              <IonCardHeader style={{ display: 'flex' }}>
                <IonCardTitle>What's Going Around</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <PathogenList
                        label="Respiratory"
                        organisms={respiratory}
                        selectedPathogen={selectedPathogen}
                        updatePathogenAction={updatePathogen}
                        pathogenType="respiratory"
                      />
                    </IonCol>
                    <IonCol>
                      <PathogenList
                        label="Gastrointestinal"
                        organisms={gastroIntestinal}
                        selectedPathogen={selectedPathogen}
                        updatePathogenAction={updatePathogen}
                        pathogenType="gastro"
                      />
                    </IonCol>
                    <IonCol>
                      <PathogenList
                        label="Syndrome"
                        organisms={syndrome}
                        selectedPathogen={selectedPathogen}
                        updatePathogenAction={updatePathogen}
                        pathogenType="syndrome"
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="12" size="12">
                  <IonCard className="gw-card side-by-side">
                    <IonCardHeader>
                      <IonCardTitle>Regional Activity</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent
                      className={`${activityImageUrl && 'severity-indicator'}`}
                    >
                      <InteractiveMap organism={selectedPathogen} />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}
