import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Home from './pages/HomePage/Home'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import { ProviderPayment } from './pages/provider-payment'
import { Covid19Resources } from './pages/covid-19-resources/Covid19Resources'
import { CareProcessModels } from './pages/care-process-models/CareProcessModels'
import EnvConstants from './data/EnvConstants'
import SkinPage from './pages/SkinPage/SkinPage'
import { GermWatch } from './pages/germwatch'
import { GermWatchPublic } from './pages/germwatch-public'
const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter
        basename={
          EnvConstants.SKIP_BASENAME_IF_AT !== window.location.origin
            ? EnvConstants.PUBLIC_URL
            : ''
        }
      >
        <IonRouterOutlet>
          <SkinPage>
            <Route exact component={Home} path="/home" />
            <Route
              exact
              component={ProviderPayment}
              path="/providers/payment"
            />
            <Route
              exact
              component={Covid19Resources}
              path="/covid-19/resources"
            />
            <Route exact render={() => <Redirect to="/home" />} path="/" />
            <Route
              exact
              component={CareProcessModels}
              path="/providers/careprocessmodels"
            />
            <Route exact component={GermWatch} path="/germwatch" />
          </SkinPage>
          <Route exact component={GermWatchPublic} path="/germwatch-public" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
